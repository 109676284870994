import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function Query(pageIndex, pageSize, sortKey, sortDirect, filters) {
  const loginKey = getToken()
  return request({
    url: './query/QueryCustomer',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters
    })
  })
}

export function Add({HoldID, VehicleID, PeopleName='', PeopleSex, IDCard='', Address='', Mobile='', Wechat='', QQ='', HomeAddress='', HomeLon, HomeLat, CompanyAddress='', CompanyLon, CompanyLat, Remark=''}) {
  const loginKey = getToken()
  return request({
    url: './add/AddPeople',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      HoldID, VehicleID, PeopleName, PeopleSex, IDCard, Address, Mobile, Wechat, QQ, HomeAddress, HomeLon, HomeLat, CompanyAddress, CompanyLon, CompanyLat, Remark
    })
  })
}

export function Delete(deviceIDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      deviceIDs
    })
  })
}

export function UnBind(deviceIDs) {
  const loginKey = getToken()
  return request({
    url: './delete/UnBindDevice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      deviceIDs
    })
  })
}

export function Modify({PeopleID, PeopleName='', PeopleSex, IDCard='', Mobile='', QQ='', Wechat='', HomeAddress='', HomeLon, HomeLat, CompanyAddress='', CompanyLon, CompanyLat, PeopleRemark=''}) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyPeople',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      PeopleID, PeopleName, PeopleSex, IDCard, Mobile, QQ, Wechat, HomeAddress, HomeLon, HomeLat, CompanyAddress, CompanyLon, CompanyLat, PeopleRemark
    })
  })
}
