<template>
  <el-popover
    placement="bottom-end"
    width="200"
    popper-class="elevation-1 v-popover"
    transition="fade-in-linear"
    trigger="click"
    v-model="show"
    @after-enter="open">
    <ul class="mypover-ul" style="list-style:none;" v-loading="loading">
      <li v-for="(item, index) in allItems" @click="itemClick(item.CID)"
      :key="index"
      :title="item.Company"
      :class="{active:innerCID == item.CID}">
      <span class="index">{{index+1}}. </span>
      {{item.Company}}</li>
    </ul>
    <div class="mypover-bottom">
      <el-button @click="refresh" type="text" icon="el-icon-refresh" class="iml-1"></el-button>
      <el-button type="text" @click="sortHandle(1)">随机</el-button>
      <el-button type="text" @click="sortHandle(2)">按联络时间排序</el-button>
    </div>

    <el-button type="text" icon="el-icon-phone" slot="reference">客户列表</el-button>
  </el-popover>
</template>

<script>
import { Query as QueryCustomer } from '@/api/customer'
export default {
  props: {
    CID: {
      type: [Number, Object, String],
      default: null
    }
  },
  data() {
    return {
      allItems: [],
      loading: false,
      show: false,
      innerCID: this.CID,
      sortKey: '',
      sortDirect: ''
    }
  },
  watch: {
    CID() {
      this.innerCID = this.CID
    }
  },
  created() {

  },
  computed: {
    listItems() {
      return this.allItems.map(i => {
        return {
          Company: i.Company,
          CID: i.CID
        }
      })
    }
  },
  methods: {
    open() {
      if (!this.allItems.length) {
        this.resetSort()
        this.fetchAll()
      }
    },
    itemClick(val) {
      // 公司点击动作
      this.innerCID = val
      this.$emit('click', val)
      //this.show=false
    },
    resetSort() {
      this.sortKey = ''
      this.sortDirect = ''
    },
    refresh() {
      this.resetSort()
      this.fetchAll()
    },
    fetchAll() {
      this.loading = true
      const promise = new Promise((resolve, reject) => {
        QueryCustomer(1, 9999, this.sortKey, this.sortDirect, []).then(({data}) => {
          this.allItems = data.list.slice()
          resolve()
          this.loading = false
        }).catch(err => {
          console.error(err) // eslint-disable-line
          reject(err)
          this.loading = false
        })
      })
      return promise
    },
    sortHandle(mode) {
      if (mode == 1) {
        // 随机
        for (let i = 0; i< this.allItems.length; i++) {
          let newItems = this.allItems.slice()
          const target = parseInt(Math.random()*this.allItems.length)
          if (target === i) {
            continue
          }
          const current = this.allItems[i]
          const next = this.allItems[target]
          newItems.splice(i, 1, next)
          newItems.splice(target, 1, current)
          this.allItems = newItems.slice()
        }
      } else if(mode == 2) {
        // 根据字段排序
        this.sortKey = 'LastContactTime'
        this.sortDirect = 'OrderBy'
        this.fetchAll()
      }
    },
    next(byHand = false) {
      if (!this.listItems.length) {
        byHand && this.fetchAll().then(() => { this.next() })
        return
      }
      const index = this.listItems.findIndex(i => i.CID == this.innerCID)
      const target = (index + 1 > this.listItems.length - 1) ? 0 : (index + 1)
      this.itemClick(this.listItems[target].CID)
    },
    pre(byHand = false) {
      if (!this.listItems.length) {
        byHand && this.fetchAll().then(() => { this.pre() })
        return
      }
      const index = this.listItems.findIndex(i => i.CID == this.innerCID)
      const target = (index - 1 < 0) ? (this.listItems.length - 1) : (index - 1)
      this.itemClick(this.listItems[target].CID)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin.scss";
.mypover-ul {
  margin: 0;
  padding-left: 0;
  min-height: 150px;
  max-height: 250px;
  overflow: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  @include scroll-bar-grey;
  li {
    height: 32px;
    line-height: 32px;
    font-size: 13px;
    padding-left: 12px;
    color: #546E7A;
    @include ellip;
    &:hover {
      background: #CFD8DC;
    }
    .index {
      color: #78909C;
    }
    &.active {
      background-color: #E0F7FA;
      color: #409EFF;
      .index {
        color: #03A9F4;
      }
    }
  }
}
.mypover-bottom {
  background-color: #E0F7FA;
}
</style>


